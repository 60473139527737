<template>
  <v-row>
    <v-col lg="2">
      <v-text-field
        v-model="data.name"
        :label="$t('serach-by-name')"
        outlined
      ></v-text-field>
    </v-col>
    <v-col lg="2" cols="12" sm="6" md="4">
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="data.created_from"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.created_from"
            :label="$t('from_date')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker
          :locale="$i18n.locale"
          v-model="data.created_from"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu1 = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu1.save(data.created_from)"
          >
            {{ $t("agree") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col lg="2" cols="12" sm="6" md="4">
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="data.created_to"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="data.created_to"
            :label="$t('to_date')"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker
          :locale="$i18n.locale"
          v-model="data.created_to"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu2 = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(data.created_to)"
          >
            {{ $t("agree") }}
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col lg="2">
      <v-icon
        @click="$emit('getData', data)"
        color="primary"
        class="ma-2"
        x-large
        >mdi-magnify</v-icon
      >
      <v-icon @click="clearData" color="primary" class="ma-2" x-large
        >mdi-close</v-icon
      >
    </v-col>
  </v-row>
</template>
<script>
export default {
  data() {
    return {
      data: {},
      menu1: false,
      menu2: false,
    };
  },
  methods: {
    clearData() {
      this.data = {};
      this.$emit("clearData");
    },
  },
  computed: {
    types() {
      return [
        {
          value: "manager",
          name: this.$t("managers"),
        },
        {
          value: "student",
          name: this.$t("students"),
        },
        {
          value: "teacher",
          name: this.$t("teachers"),
        },
      ];
    },
  },
};
</script>

<template>
  <div>
    <create
      @createData="create($event)"
      @closePopup="craeteDialog = false"
      @getCountries="getCountryData($event)"
      :dialog="craeteDialog"
      :loader="loader"
      :countryItems="countryItems"
    />
    <copy
      @copyData="copy($event)"
      @closePopup="copyDialog = false"
      :dialog="copyDialog"
      :dialogData="copyData"
      :loader="loader"
    />
    <edit
      @closePopup="editDialog = false"
      @getCountries="getCountryData($event)"
      @editData="edit($event)"
      :countryItems="countryItems"
      :dialog="editDialog"
      :dialogData="showData"
      :loader="showLoader"
      :test="showData"
    />
    <delete-popup
      @closePopup="deleteDialog = false"
      @deleteData="deleteItem($event)"
      :dialog="deleteDialog"
      :loader="loader"
      :item="deleteDialogData"
    />
    <v-row>
      <v-col>
        <school-filter
          @getData="setDataFilter($event)"
          @clearData="setDataFilter({})"
        ></school-filter>
      </v-col>
    </v-row>
    <v-row class="mb-4">
      <v-col class="d-flex justify-end"
        ><v-btn color="primary" @click="createAble"
          ><span style="color: white;">{{ $t("add") }}</span></v-btn
        ></v-col
      >
    </v-row>
    <data-table
      @updatePage="getData($event)"
      :headers="tableData.headers"
      :create="createAble"
      :copyable="copyAble"
      :editable="editAble"
      :showable="showAble"
      :deleteable="deleteAble"
      :data="tableData.data"
      :loader="loader"
      :meta="pagination"
    />
  </div>
</template>
<script>
import { School } from "@/store/common/school";
import { Country } from "@/store/common/country";
import schoolFilter from "./filter.vue";
import dataTable from "@/components/mycomponent/base/dataTable.vue";
import create from "./create.vue";
import edit from "./edit.vue";
import copy from "./copy.vue";
import deletePopup from "@/pages/Dashboard/age-group/delete.vue";

export default {
  components: {
    dataTable,
    create,
    copy,
    edit,
    deletePopup,
    schoolFilter,
  },
  data() {
    return {
      School,
      craeteDialog: false,
      copyDialog: false,
      editDialog: false,
      deleteDialog: false,
      copyData: null,
      deleteDialogData: null,
      countryItems: [],
      filterData: {},
    };
  },
  methods: {
    //get methods
    getData(page) {
      Object.assign(this.filterData, { page: page });
      School.get(this.filterData);
    },
    setDataFilter(data) {
      this.filterData = data;
      this.getData(1);
    },
    async getCountryData(params) {
      if (Country.getState.loading) return;
      await Country.get(params);
      this.countryItems = this.countryItems.concat(this.country);
    },

    //create
    createAble() {
      this.craeteDialog = true;
      this.popupType = "create";
    },
    async create(data) {
      await School.post(data);
      this.craeteDialog = false;
    },

    //copy
    copyAble(obj) {
      this.copyDialog = true;
      this.copyData = obj;
    },
    async copy(data) {
      await School.copy(data.id, { name: data.name });
      this.copyDialog = false;
    },

    //edit
    async editAble(obj) {
      this.editDialog = true;
      await School.show(obj.id);
    },
    async edit(data) {
      await School.edit(data, data.id);
      this.editDialog = false;

      this.getData(1);
    },
    //show
    showAble(obj) {
      this.$router.push(`/school/show/${obj.id}`);
    },

    //delete
    deleteAble(obj) {
      this.deleteDialog = true;
      this.deleteDialogData = obj;
    },
    async deleteItem(id) {
      await School.deleteItem(id);
      this.deleteDialog = false;
    },
  },
  computed: {
    //data
    tableData() {
      return School.tableData;
    },
    showData() {
      return School.tableData.showData;
    },
    country() {
      return Country.tableData.data;
    },

    //loaders
    loader() {
      return School.getState.loading;
    },
    showLoader() {
      return School.showState.loading;
    },

    pagination() {
      return School.tableData.meta;
    },
  },
  created() {
    this.getData(1);
    this.getCountryData({ page: 1 });
  },
};
</script>

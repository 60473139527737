<template>
  <v-dialog
    transition="dialog-top-transition"
    v-model="dialog"
    @click:outside="$emit('closePopup')"
    @keydown.esc="$emit('closePopup')"
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span>{{ $t("add") }}</span>
      </v-card-title>
      <v-card-text>
        <v-container class="d-block">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.name"
                outlined
                :label="$t('name')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="6">
              <v-text-field
                v-model="data.description"
                outlined
                :label="$t('description')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="12">
              <v-autocomplete
                :search-input.sync="searchTerm"
                :items="countryItems"
                v-model="data.country_id"
                item-text="name"
                item-value="id"
                outlined
                :label="$t('country')"
                :menu-props="{ closeOnClick: true }"
              >
                <template v-if="!stopInfinityScroll" v-slot:append-item>
                  <div
                    v-intersect="onIntersect"
                    class="pa-4 teal--text justify-center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="primary"
                    ></v-progress-circular>
                  </div>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <h4 class="mb-8">{{ $t("params") }}</h4>
          <v-row class="my-n6" v-for="(param, i) in data.params" :key="i">
            <v-col cols="12" sm="6" md="4" lg="5">
              <v-text-field
                v-model="param.name"
                outlined
                :label="$t('param_name')"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="7">
              <v-text-field
                v-model="param.value"
                outlined
                :label="$t('param_info')"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="justify-center mt-n4">
            <v-icon @click="addParam" x-large>mdi-plus</v-icon>
            <v-icon @click="deleteParam" x-large>mdi-minus</v-icon>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('closePopup')" color="blue darken-1" text>
          {{ $t("close") }}
        </v-btn>

        <v-btn
          :disabled="validate"
          :loading="loader"
          color="blue darken-1"
          text
          @click="submit"
        >
          {{ $t("save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { Country } from "@/store/common/country";
export default {
  props: ["dialog", "dialogData", "type", "loader", "countryItems"],
  data() {
    return {
      Country,
      data: {
        name: "",
        description: "",
        country_id: "",
        params: [
          {
            name: "",
            value: "",
          },
        ],
      },
      searchTerm: "",
      page: 1,
      confirmedPassword: null,
    };
  },
  methods: {
    submit() {
      this.data.params = JSON.stringify(this.data.params);
      this.$emit("createData", this.data);
    },
    async onIntersect() {
      this.page += 1;
      await this.$emit("getCountries", {
        page: this.page,
        name: this.searchTerm,
      });
    },
    addParam() {
      this.data.params.push({ name: "", value: "" });
    },
    deleteParam() {
      this.data.params.pop();
    },
  },
  computed: {
    stopInfinityScroll() {
      return Country.tableData.data.length == 0;
    },
    validate() {
      if (
        this.data.name == "" ||
        this.data.description == "" ||
        this.data.country_id == ""
      )
        return true;
      for (let i = 0; i < this.data.params.length; i++) {
        const item = this.data.params[i];
        if (item.name === "" || item.value === "") {
          return true; // validation failed
        }
      }
      return false;
    },
  },

  watch: {
    searchTerm(val) {
      setTimeout(() => {
        this.page = 1;
        this.$emit("getCountries", { page: this.page, name: val });
      }, 500);
    },
    dialog(val) {
      this.data.name = "";
      (this.data.description = ""), (this.data.country_id = "");
      this.data.params = [
        {
          name: "",
          value: "",
        },
      ];
    },
  },
};
</script>
